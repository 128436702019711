<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<div class="page_tit_area">
					<h2 class="page_tit">{{ $language.common.setting }}</h2>
					<span class="page_subtit hide">{{  item_cartel.cartl_name }}</span>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap mt-50">
			<!-- 커버 -->
			<div class="cartel_cover">
				<div class="cover_background">
					<img
						v-if="item_cartel.cartl_mobile_img_url ? item_cartel.cartl_mobile_img_url : item_cartel.cartl_img_url"
						:src="item_cartel.cartl_mobile_img_url ? item_cartel.cartl_mobile_img_url : item_cartel.cartl_img_url" alt="Register Cover Image"
					>
				</div>
				<div class="container cover_content cover_content_set clear">
					<div
						v-if="is_boss"
						class="subindex_head_setting"
					>
						<button
							class="btn_profile_setting-wh"
							@click="$bus.$emit('to', { name: 'mafia0741', params: { idx: $route.params.idx}} )"
						><span class="hide">{{ $language.common.cartel_editing }}</span></button>
					</div>
					<div class="title_area">
						<h2>{{  item_cartel.cartl_name }}</h2>
					</div>
					<div class="cartel_area clear">
						<div class="badge_area">
							<span class="badge_30 badge_30_music_bl" :class="'badge_30_' + item_cartel.type + '_bl'">
								<em class="hide">{{ item_cartel.type }}</em>
							</span>
						</div>
						<div class="text_area">
							<div class="level">{{ item_cartel.cartl_level_name }}</div>
							<div class="member">
								{{ $language.common.memberCount }}<b>{{  item_cartel.current_member_count }}</b>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- //커버 -->
			<!-- 카르텔소개 -->
			<div class="ct_introduce">
				<div class="container">
					<div class="row">
						<h3 class="hide">{{ $language.common.cartel_intro }}</h3>
						<p class="introduce_desc fw-500">
							{{  item_cartel.cartl_desctn }}
						</p>
					</div>
				</div>
			</div>
			<!-- //카르텔소개 -->
			<!-- 설정 -->
			<div class="subindex_wrap">

				<template
					v-for="(group, g_index) in menu_list"
				>
				<div
					v-if="group.permission"
					:key="'group_' + g_index"
					class="subindex_item"
				>
					<div class="container">
						<div class="subindex_box_1">
							<ul class="settings_list">
								<template
									v-for="(menu, m_index) in group.items"
								>
								<li
									v-if="menu.permission && !menu.not_use"
									:key="'menu_' + g_index + '_' + m_index"
									class="settings_list_item"
								>
									<button
										@click="$emit('to', menu.to)"
									>
										<div class="cell_area">
											<div class="cell_tit">{{ menu.name }}</div>
											<div class="cell_action"><span v-if="menu.is_sub" class="text">{{ menu.is_sub }}</span><i class="icon icon-arrow-right"></i></div>
										</div>
									</button>
								</li>
								</template>
							</ul>
						</div>
					</div>
				</div>
				</template>

			</div>
			<!-- //설정 -->
		</div>

		<Popup_confirm
			v-if="is_confirm"
			@click="postCartelActivity(true)"
			@cancel="postCartelActivity(false)"
		>
			<template v-slot:title>{{ $language.common.disclosure_active }}</template>
			<template
				v-if="item_cartel.is_confirm"
				v-slot:main-txt
			>{{ $language.disclosure_message.reveal_01 }}</template>
			<template
				v-if="item_cartel.is_confirm"
				v-slot:sub-txt
			>{{ $language.disclosure_message.reveal_02 }}</template>
			<template
				v-else
				v-slot:sub-txt
			>{{ $language.common.cartel_join }}</template>
			<template
				v-slot:name-cancel
			>{{ $language.common.Nondisclosure }}</template>
			<template
				v-slot:name-confirm
			>{{ $language.common.disclosure }}</template>
		</Popup_confirm>
	</div>
</template>

<script>
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'mafia074'
		,
		components: {Popup_confirm},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.setting_cartel
					, title: this.$language.common.setting_cartel
					, type: 'cartel_sub'
					, not_footer: true
					, not_header: true
					, from: 'mafia049'
				}
				, item_cartel: {}
				, is_trans: false
				, is_confirm: false
				, item_permission: {}
				, is_all: true
			}
		}
		, computed: {

			is_mine: function(){
				let t = false

				console.log(this.item_cartel.id, this.user.id)
				if(this.item_cartel.user) {
					if (this.item_cartel.user.indexOf(this.user.id) > -1) {
						t = true
					}
				}

				return t
			}
			, is_active: function(){
				let t = this.$language.common.Nondisclosure

				if(this.item_cartel.is_active){
					t = this.$language.common.disclosure
				}

				return t
			}
			, is_boss: function(){
				let t = false

				if(this.item_permission.cartl_member_div_code == 'CA02500001'){
					t = true
				}
				return t
			}
			, is_manager: function (){

				let t = false
				try{
					if(this.is_boss || this.item_permission.cartl_member_div_code == 'CA02500002'){
						t = true
					}
				}catch (e) {
					t = false
					console.log(e)
				}
				return t
			}
			, is_underboss_manage: function(){
				let t = false
				if(this.item_permission.cartl_admin_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_board_manage: function(){
				let t = false
				if(this.item_permission.cartl_board_creation_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_member_manage: function(){
				let t = false
				if(this.item_permission.cartl_member_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_member_grade_manage: function(){
				let t = false
				if(this.item_permission.cartl_member_grade_mangement_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_notice_manage: function(){
				let t = false
				if(this.item_permission.cartl_notice_mttrs_reg_fg == 'Y'){
					t = true
				}
				return t
			}
			, menu_list: function() {
				return [
					{ permission: this.is_all, items: [
							{
								// 활동정보 공개
								permission: this.is_all
								, to: {}
								, name: this.$language.common.disclosure_active
								, not_use: true
								, is_sub: this.is_active
							}
							,
							{
								// 알림
								permission: this.is_all
								, to: { name: 'mafia076', params: { idx: this.$route.params.idx}}
								, name: this.$language.common.notify
							}
						]
					}
					, {
						permission: this.is_all, items: [
							{
								// 맴버 보기
								permission: this.is_all,
								to: {name: 'mafia082', params: {idx: this.$route.params.idx}},
								name: this.$language.common.view_member
							}
							, {
								// 운영자 관리
								permission: this.is_underboss_manage,
								to: {name: 'mafia084', params: {idx: this.$route.params.idx}},
								name: this.$language.cartel.manage_under_boss
							}
							, {
								// 맴버등급관리
								permission: this.is_member_grade_manage,
								to: {name: 'mafia0742', params: {idx: this.$route.params.idx}},
								name: this.$language.common.manage_grade_member
							}
							, {
								// 카르텔 탈퇴
								permission: !this.is_boss,
								to: {name: 'mafia083', params: {idx: this.$route.params.idx}},
								name: this.$language.common.withdrawal_cartel
							}
						]
					}
					, {
						permission: this.is_boss || this.is_manager, items: [
							{
								// 게시판 관리
								permission: this.is_board_manage,
								to: {name: 'mafia122', params: {idx: this.$route.params.idx}},
								name: this.$language.notice.manage
							}
							,{
								// 마피아TV 관리
								permission: this.is_board_manage,
								not_use: !this.$is_on_tv,
								to: {name: 'MafiaTvManage', params: {idx: this.$route.params.idx}},
								name: this.$language.tv.title_tv
							}
							, {
								// 투표 게시판 관리
								permission: this.is_board_manage,
								to: {name: 'CartelVotingBoardList', params: {idx: this.$route.params.idx}},
								name: this.$language.voting.title_voting_board_manage
							}
							, {
								// 구독 관리
								permission: this.is_board_manage,
								to: {name: 'SubscribeManagement', params: {idx: this.$route.params.idx}},
								name: this.$language.cartel_new.title_subscribe_management
							}
							, {
								// 후원 내역
								permission: this.is_boss,
								to: {name: 'CartelSupportHistory', params: {idx: this.$route.params.idx}},
								name: this.$language.cartel_new.title_support_history
							}
							, {
								// 카르텔 통계
								permission: this.is_boss || this.is_manager,
								to: {name: 'mafia0911', params: {idx: this.$route.params.idx}},
								name: this.$language.cartel.analyze_cartel
							}
							, {
								// 맴버 관리
								permission: this.is_member_manage,
								to: {name: 'mafia091', params: {idx: this.$route.params.idx}},
								name: this.$language.common.manageMember
							}
						]
					}
					, {
						permission: this.is_boss, items: [
							{
								// 카르텔 폐쇄
								permission: this.is_boss,
								to: {name: 'mafia0831', params: {idx: this.$route.params.idx}},
								name: this.$language.common.closer_cartel
							}
						]
					}
					, {
						permission: !this.is_boss, items: [
							{
								// 구독 내역
								permission: !this.is_boss,
								to: {name: 'CartelSubscribeList', params: {idx: this.$route.params.idx}},
								name: this.$language.cartel_new.title_subscribe_history
							}
						]
					}
				]
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data

						switch (this.item_cartel.cartl_concern_sphere_code){
							case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
							case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
							case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
							case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,postCartelActivity: async function(is_active){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_activity
						, data: {
							cartel_id: this.$route.params.idx
							, is_active: is_active
						}
						, name: 'postCartelActivity'
					})
					if(result.success){
						this.$set(this.item_cartel, 'is_active', is_active)
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					console.log(e)
				}finally {
					this.is_confirm = false
					this.$bus.$emit('on', false)
				}
			}
			, getPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.item_permission = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getData()
			await this.getPermission()
		}
	}
</script>